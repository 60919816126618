@import '../../Styles/variables.scss';

.add-company-main-container .title-container .main {
  font-size: 1.25rem;
  font-weight: 500;
  color: $primary-purple !important;
}

.PhoneInput {
  .PhoneInputInput:focus-visible,
  .PhoneInputInput:hover {
    flex: 1 1;
    min-width: 0;
    border-radius: 5px !important;
    height: 40px;
    border: 1px solid rgba($primary-purple, 0.8) !important;
    outline: none;
  }
}
