@import '../../Styles/variables.scss';

.ndc-action-body {
  display: flex;
  flex-direction: column;
  width: 100%;

  .report-details {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .report-type {
      display: flex;
      flex-direction: row;
      width: 60%;

      .name-time-container {
        display: flex;
        flex-direction: column;
        width: 70%;
        margin-bottom: 0.5rem;
      }

      .name {
        font-weight: 600;
        color: $common-filter-color;
      }

      .icon {
        display: flex;
        align-items: center;
        width: 30%;
      }

      .empty {
        font-weight: 600;
        color: #cacaca;
      }
    }

    .report-link {
      display: flex;
      flex-direction: row;
      width: 40%;

      .version {
        font-weight: 600;
        color: #cacaca;
      }

      .link {
        margin-left: 1rem;
      }
    }
  }
}
