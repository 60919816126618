.ndc-details-view {
  .card-container {
    margin-bottom: 16px;
    width: 100%;
    border-radius: 10px;

    .co-benifits-view {
      .title {
        color: rgba(58, 53, 65, 0.8);
        font-weight: 600;
        margin-left: 0.5rem;
      }
    }

    .action-btns {
      margin-top: 25px;
      margin-bottom: -15px;
      justify-content: end;
    }
  }

  .fix-height {
    height: 350px;
  }
}