@import '../../Styles/variables.scss';

.login-language-selection-container .ant-select-selection-item {
  color: $primary-color !important;
  font-weight: 500;
}

.login-language-selection-container .ant-select-selector {
  color: $primary-color !important;
  border: 1px solid $primary-color !important;
}

.login-language-selection .ant-select-item-option-selected .ant-select-item-option-content {
  color: $primary-color !important;
}
